import React from "react";
import styles from "./splitStackChart.module.css";

const colorMap = {
  Critical: "rgb(240 77 78 / 76%)",
  High: "#FF9998",
  Medium: "#FFC000",
  Low: "#FFEC9A",
  "Pot Stockout": "#7705BC",
  "RMPM Shortage": "#AD30C3",
  "Mfg. Disruption": "#01B2FE",
  "Increase in DOH": "#A293FF",
};

const ExceptionBar = (props) => {
  const widthPercentage = (props?.value / props?.totalSum) * 100;
  const heightValue = props?.totalHeight;

  return (
    <div
      className={styles.exceptionBar}
      style={{
        height: heightValue + "vh",
        width: widthPercentage + "%",
        backgroundColor: colorMap[props?.label],
      }}
    >
      {widthPercentage > 5 && ( // Only show text if width > 5%
        <span className={styles.exceptionBarTitle}>
          {props?.value}
        </span>
      )}
    </div>
  );
};

const SplitStackChartAlert = (props) => {
  const legendContent = props?.legendContent;

  const exceptionTileTotalSum = legendContent.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.value,
    0
  );

  const order = [
    "High",
    "Medium",
    "Low",
    "RMPM Shortage",
    "Mfg. Disruption",
    "Pot Stockout",
    "Increase in DOH",
  ];

  // Sort legendContent based on the defined order
  const sortedLegendContent = [...legendContent].sort((a, b) => {
    return order.indexOf(a.label) - order.indexOf(b.label);
  });

  return (
    <div className={styles.columnStack}>
      {!props?.alertTable && <span className={styles.title}>{props?.title}</span>}
      <div className={styles.exceptionOverviewBarContainer}>
        {sortedLegendContent?.map((item, index) => (
          // <Tooltip title={`${item.label}: ${item.value}`}> {/* Add Tooltip */}
          <ExceptionBar
            key={index}
            label={item?.label}
            value={item?.value}
            totalSum={exceptionTileTotalSum}
            totalHeight={4.5}  // You can adjust this value
          />
          // </Tooltip>
        ))}
      </div>
      <div className={styles.legendsContainer}>
        {sortedLegendContent.some(item => item.label === "RMPM Shortage") ? (
          <div className={styles.legendItem}>
            <span
              className={styles.legendColorBox}
              style={{ backgroundColor: colorMap["RMPM Shortage"] }}
            ></span>
            <span className={styles.legendLabel}>RMPM Shortage</span>
          </div>
        ) : ("")
          // (
          //   sortedLegendContent.map((item, index) => (
          //     <div key={index} className={styles.legendItem}>
          //       <span
          //         className={styles.legendColorBox}
          //         style={{ backgroundColor: colorMap[item.label] }}
          //       ></span>
          //       <span className={styles.legendLabel}>{item.label}</span>
          //     </div>
          //   ))
          // )
        }
      </div>
    </div>
  );
};

export default SplitStackChartAlert;
