import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect, useCallback } from 'react';
import styles from './DemandSupply.module.css';
import DemandContent from './DemandContent';
import KeyInsightsContent from './KeyInsightsContent';
import { demandSupplyAPI, keyInsightApi } from 'api/demo';
import { CircularProgress } from "@mui/material";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={styles.tabPanel}>
          {children}
        </div>
      )}
    </div>
  );
};

const DemandSupply = ({ alertId, materialId, plantCode, week }) => {
  const [value, setValue] = useState(0);
  const [demandSupplyDetails, setDemandSupplyDetails] = useState(null);
  const [keyInsightDetails, setKeyInsightsDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingKeyInsight, setLoadingKeyInsight] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Function to call Demand API
   */
  const fetchDemandData = useCallback(async () => {
    try {
      const data = await demandSupplyAPI(alertId);
      setDemandSupplyDetails(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching alert details:", error);
      setLoading(false);
    }
  }, [alertId]);



  /**
   * Function to call Key Insight
   */
  const keyInsight = useCallback(async () => {
    try {
      const data = await keyInsightApi(materialId, plantCode, week);
      setKeyInsightsDetails(data);
      setLoadingKeyInsight(false);
    } catch (error) {
      console.error("Error fetching alert details:", error);
      setLoadingKeyInsight(false);
    }
  }, [materialId]);

  useEffect(() => {
    fetchDemandData();
    keyInsight();
  }, [fetchDemandData, keyInsight]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className={styles.TabContainer}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Demand Supply" sx={{
            '@media (max-width:1500px)': {
              fontSize: '13px',
            },
          }} />
          <Tab label="Key Insights" sx={{
            '@media (max-width:1500px)': {
              fontSize: '13px',
            },
          }} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0} padding={0}>
        {loading ? (
          <div className={styles.loaderContainer}><CircularProgress /></div>
        ) : (demandSupplyDetails && <DemandContent demandDetails={demandSupplyDetails} />
        )}
      </TabPanel>
      <TabPanel value={value} index={1} padding={0}>
        {
          loadingKeyInsight ? (
            <div className={styles.loaderContainer}><CircularProgress /></div>
          ) : (keyInsightDetails && <KeyInsightsContent keyInsightContent={keyInsightDetails} alertId={alertId} materialId={materialId} plantCode={plantCode} />
          )
        }

      </TabPanel>
    </div>
  );
};

export default DemandSupply;

