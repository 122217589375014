import React, { useState } from "react";
import styles from './DemandSupply.module.css';
import BarAndLineComboChart from 'components/barAndLineComboChart/barAndLineComboChart';
import PeriodLineTab from "../../../../components/periodLineTab/periodLineTab";
import DemandTable from "components/table/demandTable";
import { format } from 'date-fns';
import CustomTooltip from "utils/CustomTooltip";
import { convertToLakhs } from "utils/convertValueIntoLakhs";


const DemandContent = ({ demandDetails }) => {

    /**
     * Function to find Implacted Week number
     * @returns 
     */
    const findImplactedWeek = () => {
        if (demandDetails && demandDetails['Overview'][0]) {
            const implactedWeek = demandDetails['Overview'][0].data.find(a => a.label === 'Impacted week');
            return "Week " + implactedWeek.value;
        }
    };

    /**
     * Function to find Base Unit of Measure
     * @returns 
     */
    const findBaseUnitMeasure = () => {
        if (demandDetails && demandDetails['Overview'][0]) {
            const MaterialOverview = demandDetails['Overview'].find(a => a.title === 'Material Overview');
            const baseUnitOfMeasure = MaterialOverview.data.find(a => a.label === 'Base unit of measure');
            return baseUnitOfMeasure.value;
        }
    };

    const [selectedLine, setSelectedLine] = useState(findImplactedWeek());
    const [tableValues, setTableValues] = useState(demandDetails['Demand Supply'][findImplactedWeek()]);

    const horizontalLineHandler = (tabname) => {
        setTableValues(demandDetails['Demand Supply'][tabname])
        setSelectedLine(tabname);
    };

    return (
        <div className={styles.demandContainer}>
            <div className={styles.leftDemandContainer}>
                {/* <div className={styles.alertDes}>
                     <span className={styles.alertDesTitle} >Alert Description</span> 
                     <span>{demandDetails["Alert Description"]}</span> 
                </div> */}
                {demandDetails.Overview.map((overview, index) => (
                    <div key={index} className={styles.alertOverview}>
                        <span className={styles.header}>{overview.title}</span>
                        {overview.data.map((data, dataIndex) => (
                            <div key={`${index}-${dataIndex}`} className={styles.cardContain}>
                                <span className={styles.label}>{data.label}</span>
                                <span className={styles.value}>
                                    { data.label === 'Supplier Name' && data.value.length > 1 ? <></> : <span className={styles.colon}>:</span> }
                                    {data.label === 'Supplier Name' ? (
                                        <ul className={styles.supplierList}>
                                            {data.value.map((supplier, supplierIndex) => (
                                                <li key={supplierIndex} className={`${data.value.length > 1 ? styles.supplierItemMultiRecords : styles.supplierItem}`}>
                                                    {supplier}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <span className={styles.ellipse}>
                                            {data.label.includes("Date") ? format(new Date(data.value), 'dd-MMM-yy') :
                                                data.label === "Revenue Opportunity Loss" ? convertToLakhs(data.value) :
                                                    <CustomTooltip title={data.value}>{data.value}</CustomTooltip>}
                                        </span>
                                    )}
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className={styles.rightDemandContainer}>
                <span className={styles.demandTitle}>Demand Supply Gap</span>
                <PeriodLineTab selectedLine={selectedLine} horizontalLineHandler={horizontalLineHandler} values={demandDetails['Demand Supply'].weeks}>
                    <BarAndLineComboChart details={tableValues} weekLabel={selectedLine} />
                    <DemandTable data={tableValues} unitOfMeasure={findBaseUnitMeasure()} weekLabel={selectedLine} />
                </PeriodLineTab>
            </div>
        </div>
    );
};

export default DemandContent;