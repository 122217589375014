import { LogLevel } from "@azure/msal-browser";
import { devCred, falguniCred, himanshiCred, prodCred, qaCred } from "msalConfigCreditional";

const authObj = () => {
  if(process.env.REACT_APP_ENV === 'production') {
    return prodCred;
  } else if(process.env.REACT_APP_ENV === 'QA') {
    return qaCred;
  } else if(process.env.REACT_APP_ENV === 'DEV') {
    return devCred;
  } else {
    //return himanshiCred;
    return falguniCred;
  }
}

export const msalConfig = {
  auth : authObj(),
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["user.read"],
};
