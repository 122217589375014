import React from "react";
import styles from "./login.module.css";
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "./authConfig";
import unilever from "../../assets/images/loginPage/loginHulLogo.svg";
import samarth from "../../assets/images/samarth.svg";

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.leftloginContainer}>
        <img src={unilever} style={{ width: '40%' }} />
        <div className={styles.log}>
          <img src={samarth} style={{ width: '50%' }} />
          <h3 className={styles.welcomeText}>Welcome to Samarth Portal !!</h3>
          <button className={styles.loginButton} onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
      <div className={styles.rightloginContainer}>
        <div className={styles.descriptionContainer}>
          <span className={styles.supplyName}>Supply Chain Nerve Center</span>
          <span style={{ fontSize: '12px' }}>unlocking Supply Chain Capabilities with Plan, Source, Make, Deliver</span>
          <span className={styles.tagline}>Intelligent | Integrated | Automated</span>
        </div>
        <div className={styles.footer}>
          <span style={{ fontSize: "10px" }}>Version: 1.1.2</span>
          <span style={{ fontSize: "10px" }}>Release Date: Thursday, November 21, 2024 10:00 AM IST</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
