const entityExceptionsConstants = {
  entityTitleText: "Entity - ",
  exceptionsEventsDisplayText: "Exceptions Events",
  criticalExceptionsDisplayText: "Critical Exceptions",
  viewAlertDetailsButtonDisplayText: "View Alert Details",
  previousRoute: -1,
  routeToAlertDetailsPage: "/home/alert_details",
};

export default entityExceptionsConstants;
