import React, { useEffect, useState } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import styles from "./barAndLineComboChart.module.css";
import { format } from "date-fns";

const BarAndLineComboChart = ({ details, type, weekLabel }) => {
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short' };
        const date = new Date(dateString.split('-').reverse().join('-'));
        return date.toLocaleDateString('en-US', options);
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const legendStyle = {
        fontSize: windowWidth <= 1500 ? '10px' : '12px',
        paddingBottom: windowWidth <= 1500 ? '10px' : '15px',
        color: 'black',
    };

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("-");
        return new Date(`${year}-${month}-${day}`);
    };

    // Custom Tooltip component
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltip}>
                    <span className={styles.toolTipLabel}>{`${format(parseDate(label), "d MMM")}`}</span>
                    {payload.map((item, index) => (
                        <p key={index} className={styles.tooltipItem}>
                            <span style={{ fontWeight: 600 }}>{item.name}</span> : {`${item.value.toFixed(1)}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    // Format the X-axis label based on the record count
    const tickFormatter = (tick) => {
        if (details.length === 1) {
            return "WK " + weekLabel.split(' ')[1];
        } else {
            return formatDate(tick); // Regular date format for multiple records
        }
    };

    return (
        <div className={styles.chartContainer}>
            <ComposedChart
                width={windowWidth <= 1500 ? windowWidth * 0.61 : 980}
                height={windowWidth <= 1500 ? 180 : 200}
                data={details}
                margin={{
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="Date"
                    tickFormatter={tickFormatter} // Use the custom tickFormatter
                    tickLine={false}
                    className={styles.BarLabel}
                />
                <YAxis domain={[0, 'auto']} tickLine={false} className={styles.BarLabel} />
                <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{
                        fontSize: windowWidth <= 1500 ? '10px' : '12px',
                        padding: windowWidth <= 1500 ? '4px' : '6px',
                    }}
                />
                <Legend
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={legendStyle}
                    formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
                />
                {type === "simulate" ? (
                    <>
                        <Bar dataKey={(entry) => entry["Projected Demand for RM (in T)"]?.value} name="Projected RM Demand" barSize={20} fill="#005EEE" />
                        <Bar dataKey={(entry) => entry["Projected RM Availability Old (in T)"].value} name="Projected RM Avail-Old" barSize={20} fill="#01B2FE" />
                        <Bar dataKey={(entry) => entry["Projected RM Availability New (in T)"].value} name="Projected RM Avail-New" barSize={20} fill="#9C44BF" />
                        <Line type="monotone" dataKey="Ending on Hand Old (in T)" name="Ending on Hand-Old" stroke="#001F82" strokeWidth={2} dot={{ fill: '#001F82', r: 2 }} />
                        <Line type="monotone" dataKey="Ending on Hand New (in T)" name="Ending on Hand-New" stroke="#FF9E01" strokeWidth={2} dot={{ fill: '#FF9E01', r: 2 }} />
                    </>
                ) : (
                    <>
                        <Bar dataKey={(entry) => entry["Projected Demand"].value} name="Projected Demand" barSize={20} fill="#005EEE" />
                        <Bar stackId="a" dataKey={(entry) => entry["Opening Stocks"]} name="Opening Stocks" barSize={20} fill="#01B2FE" />
                        <Bar stackId="a" dataKey={(entry) => (entry["P1 Alert - Demand vs Opening Stocks"] < 0 ? Math.abs(entry["P1 Alert - Demand vs Opening Stocks"]) : null)} name="P1 Alert - Demand vs Opening Stocks" barSize={20} fill="rgb(255, 100, 124)"/>
                        {/* <Bar dataKey={(entry) => (entry["SP2 Alert - Safety Norm vs Opening Stocks"] < 0 ? entry["P2 Alert - Safety Norm vs Opening Stocks"] : null)} name="P2 Alert - Safety Norm vs Opening Stocks" barSize={20} fill="rgb(255, 236, 154)"/> */}
                        <Line type="monotone" dataKey={(entry) => entry["Safety Norm"]} name="Safety Norm" stroke="#82ca9d" fill="#82ca9d" strokeWidth={2}/>
                        <Line type="monotone" dataKey={(entry) => entry["P2 Alert - Safety Norm vs Opening Stocks"]} name="P2 Alert - Safety Norm vs Opening Stocks" stroke="rgb(255, 236, 154)" fill="rgb(255, 236, 154)" strokeWidth={2}/>

                    </>
                )}
            </ComposedChart>
        </div>
    );
}

export default BarAndLineComboChart;
