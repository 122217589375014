export const convertToLakhs = (value) => {
  const numericValue = Number(value);
  if (numericValue === undefined || numericValue === null || numericValue === 0) return 0;

  if (numericValue >= 100000 || numericValue <= -100000) {
    const lakhs = numericValue / 100000;
    return lakhs.toFixed(1) + " L";
  }

  return numericValue.toFixed(1);
};

export const convertToCrores = (value) => {
  const numericValue = Number(value);
  if (numericValue === undefined || numericValue === null || numericValue === 0) return 0;

  return (numericValue / 10000000).toFixed(1) + " Cr";
};
