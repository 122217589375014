import React, { useState } from "react";

export const CollapseContext = React.createContext();

export const CollapseProvider = ({ children }) => {
    const [openRows, setOpenRows] = useState({});

    const handleCollapseToggle = (alertId, isExecuteAction = false) => {
        const isClosing = openRows[alertId];
        const delay = isExecuteAction ? 2000 : 0;

        if (isClosing) {
            setTimeout(() => {
                setOpenRows((prevOpenRows) => ({
                    ...prevOpenRows,
                    [alertId]: false,
                }));
            }, delay);
        } else {
            setOpenRows((prevOpenRows) => ({
                ...prevOpenRows,
                [alertId]: true,
            }));
        }
    };

    return (
        <CollapseContext.Provider value={{ openRows, handleCollapseToggle }}>
            {children}
        </CollapseContext.Provider>
    );
};

