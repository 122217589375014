import React, { createContext, useState, useContext } from "react";

export const SelectedAlertsContext = createContext();

export const SelectedAlertsProvider = ({ children }) => {
  const [selectedAlerts, setSelectedAlerts] = useState([]);

  return (
    <SelectedAlertsContext.Provider value={{ selectedAlerts, setSelectedAlerts}}>
      {children}
    </SelectedAlertsContext.Provider>
  );
};