import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import styles from "./table.module.css";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "#DAE8FF",
        borderRight: "2px solid #FFF",
        padding: "5px",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "& td, & th": {
        textAlign: "center",
    },
}));

const SupplierLeadTimeTable = ({ data }) => {
    return (
        <>
            <TableContainer className={styles.table} style={{ maxHeight: "300px" }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Supplier Name</StyledTableCell>
                            <StyledTableCell>Supplier Code</StyledTableCell>
                            <StyledTableCell>Production Lead time</StyledTableCell>
                            <StyledTableCell>Transit time</StyledTableCell>
                            <StyledTableCell>GR Processing Time</StyledTableCell>
                            <StyledTableCell>Average Inspection Time</StyledTableCell>
                            <StyledTableCell>Safety Time</StyledTableCell>
                            <StyledTableCell>Total time horizon</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((d, index) => {
                            const productionTime = parseInt(d.ProductionTime) || 0;
                            const transitTime = parseInt(d.TransitTime) || 0;
                            const grProcessingTime = parseInt(d.GRProcessingTime) || 0;
                            const averageInspectionTime = parseInt(d.AverageInspectionTime) || 0;
                            const safetyTime = parseInt(d.SafetyTime) || 0;

                            const totalTime = productionTime + transitTime + grProcessingTime + averageInspectionTime + safetyTime;

                            return (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{d.SupplierName}</StyledTableCell>
                                    <StyledTableCell>{d.SupplierCode}</StyledTableCell>
                                    <StyledTableCell>{productionTime}</StyledTableCell>
                                    <StyledTableCell>{transitTime}</StyledTableCell>
                                    <StyledTableCell>{grProcessingTime}</StyledTableCell>
                                    <StyledTableCell>{averageInspectionTime}</StyledTableCell>
                                    <StyledTableCell
                                        style={{
                                            backgroundColor: safetyTime === 0 ? "rgb(255, 236, 154)" : "inherit",
                                            // fontWeight: safetyTime === 0 ? "bold" : "inherit",
                                        }}
                                    >
                                        {safetyTime}
                                    </StyledTableCell>
                                    <StyledTableCell> {totalTime} </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default SupplierLeadTimeTable;

