import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { format } from "date-fns";
import { styled } from "@mui/system";
import styles from "./table.module.css";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "#DAE8FF",
        borderRight: "2px solid #FFF",
        padding: "5px",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "& td, & th": {
        textAlign: "center",
    },
}));

const ProjectedSupply = ({ data, materialId, plantCode }) => {

    const [sortDirection, setSortDirection] = useState("asc");

    const handleSort = () => {
        setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"))
    }

    const sortedData = [...(data || [])].sort((a, b) => {
        const dateA = new Date(a?.PodeliveryUpdatedDate);
        const dateB = new Date(b?.PodeliveryUpdatedDate);
        return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });

    return (
        <>
            {sortedData.length > 0 ? (
                <TableContainer className={styles.table} style={{ maxHeight: "300px" }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Supplier Code</StyledTableCell>
                                <StyledTableCell>Supplier Name</StyledTableCell>
                                <StyledTableCell>Open PO Quantity (in BUoM)</StyledTableCell>
                                <StyledTableCell onClick={handleSort}>
                                    <div className={styles.sortColumn}>
                                        PO Delivery Date
                                        {sortDirection === "asc" ? (
                                            <ArrowUpwardIcon className={styles.sortIcon} />
                                        ) :
                                            (<ArrowDownwardIcon className={styles.sortIcon} />)}
                                    </div>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((d, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{d?.VendorCode}</StyledTableCell>
                                    <StyledTableCell>{d?.VendorName}</StyledTableCell>
                                    <StyledTableCell>{d?.OpenPOQuantity.toFixed(1)}</StyledTableCell>
                                    <StyledTableCell>{format(new Date(d?.PodeliveryUpdatedDate), "dd-MMM-yy")}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography
                    variant="body1"
                    className={styles.displayText}
                >
                    Open PO is not available for  Material ID:{' '}
                    <Typography component="span" className={styles.text}>
                        {materialId}
                    </Typography>{' '}
                    and  Plant Code:{' '}
                    <Typography component="span" className={styles.text}>
                        {plantCode}
                    </Typography>
                </Typography>
            )}
        </>
    );
};

export default ProjectedSupply;
