import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import { format } from "date-fns";
import styles from "./table.module.css";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { convertToLakhs } from "utils/convertValueIntoLakhs";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "#EAF2FF",
        borderRight: "2px solid #FFF",
        padding: "5px",
        height: '10px',
        '@media (max-width: 1500px)': {
            padding: "2px",
            height: '8px',
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "& td, & th": {
        paddingTop: 5,
        paddingBottom: 5,
    },
}));

const simulateP = [
    "Projected Demand for RM (in T)",
    "Projected RM Availability Old (in T)",
    "Projected RM Availability New (in T)",
    "Ending on Hand Old (in T)",
    "Ending on Hand New (in T)",
    "Revenue at Risk Addressed (in INR L)",
];

const demandp = [
    "Projected Demand",
    "Opening Stocks",
    "P1 Alert - Demand vs Opening Stocks",
    "Safety Norm",
    "P2 Alert - Safety Norm vs Opening Stocks",
    "Projected Supply",
    "Ending on Hand",
];

const DemandTable = ({ data, type, unitOfMeasure, weekLabel }) => {
    const [expandedParameter, setExpandedParameter] = useState(null);
    const parameters = type === 'simulate' ? simulateP : demandp;

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("-");
        return new Date(`${year}-${month}-${day}`);
    };

    const handleParameterClick = (param) => {
        setExpandedParameter((prev) => (prev === param ? null : param));
    };

    const getAdditionalDataKeys = (param) => {
        const additionalData = data[0][param];
        return additionalData && typeof additionalData === 'object' ? Object.keys(additionalData).filter(key => key !== 'value') : [];
    };

    const renderCellValue = (param, value) => {
        if (value === null || value === undefined) {
            return "";
        }

        if (typeof value === "object") {
            return value.hasOwnProperty('value') ? value?.value?.toFixed(1) : 0;
        } else {
            return param === "Revenue Opportunity Loss" ? convertToLakhs(value, unitOfMeasure) : unitOfMeasure === 'T' ? Math.round(value) : value.toFixed(1);
        }
    };

    return (
        <TableContainer className={styles.demandTable}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Parameters</StyledTableCell>
                        {
                            data.length === 1 ?
                                <StyledTableCell>
                                    WK {weekLabel.split(' ')[1]}
                                </StyledTableCell> :
                                data.map((entry, index) => (
                                    <StyledTableCell key={index}>
                                        {format(parseDate(entry.Date), "d MMM")}
                                    </StyledTableCell>
                                ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {parameters.map((param, index) => (
                        <React.Fragment key={index}>
                            <StyledTableRow>
                                <StyledTableCell
                                    onClick={() => handleParameterClick(param)}
                                    className={styles.paramterRow}
                                    style={{ fontWeight: '600' }}>
                                    {typeof data[0][param] === 'object' && data[0][param] !== null && Object.keys(data[0][param]).length > 0 ? (
                                        expandedParameter === param ? (
                                            <IndeterminateCheckBoxOutlinedIcon className={styles.plusIcon} />
                                        ) : (
                                            <AddBoxOutlinedIcon className={styles.plusIcon} />
                                        )
                                    ) : (
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                    )}
                                    {param.includes("Revenue") ? param : param + " ( in "+unitOfMeasure+")" }
                                </StyledTableCell>
                                {data.map((entry, idx) => (
                                    <StyledTableCell key={idx} sx={{ textAlign: 'center' }} style={{ backgroundColor: param === 'P1 Alert - Demand vs Opening Stocks' && Math.sign(entry[param]) === -1 ? "#FF647C" : param === 'P2 Alert - Safety Norm vs Opening Stocks' && Math.sign(entry[param]) === -1 ? "#FFEC9A" : "" }}>
                                        {renderCellValue(param, entry[param])}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                            {expandedParameter === param && getAdditionalDataKeys(param).map((key, subIdx) => (
                                <StyledTableRow key={subIdx}>
                                    <StyledTableCell>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{key + " ( in "+unitOfMeasure+")"}</StyledTableCell>
                                    {data.map((entry, idx) => (
                                        <StyledTableCell key={idx} sx={{ textAlign: 'center' }}>
                                            {entry[param][key] !== undefined ? (
                                                typeof entry[param][key] === "number" ? entry[param][key].toFixed(1) : 0) : 0}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DemandTable;
