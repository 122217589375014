import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import styles from "./table.module.css";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "#DAE8FF",
        borderRight: "2px solid #FFF",
        padding: "5px",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ isImpacted }) => ({
    "& td, & th": {
        textAlign: "center",
    },
    backgroundColor: isImpacted ? "#EAF2FF" : "inherit",
}));

const InventoryStatus = ({ data, plantCode }) => {

    const safeParseFloat = (value) => {
        return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    };

    const filteredData = data.filter(d => {
        const isImpacted = d.PlantCode === plantCode;
        const unrestrictedStock = safeParseFloat(d?.UnrestrictedStock);
        const unrestrictedStockToVendor = safeParseFloat(d?.UnrestrictedStockToVendor);
        const stockInQualityInspection = safeParseFloat(d?.StockInQualityInspection);

        return isImpacted || unrestrictedStock > 0 || unrestrictedStockToVendor > 0 || stockInQualityInspection > 0;
    });

    const sortedData = [...filteredData].sort((a, b) => {
        if (a.PlantCode === plantCode) return -1;
        if (b.PlantCode === plantCode) return 1;
        return 0;
    });

    return (
        <>
            <TableContainer className={styles.table} style={{ maxHeight: "300px" }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {["Plant Code", "Plant Name", "Base Unit Of Measure", "Unrestricted Stock", "STV", "Stock In Quality Inspection"].map((column) => (
                                <StyledTableCell key={column}>{column}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((d, index) => (
                            <StyledTableRow key={index} isImpacted={d.PlantCode === plantCode}>
                                <StyledTableCell>{d.PlantCode}</StyledTableCell>
                                <StyledTableCell>{d.PlantName}</StyledTableCell>
                                <StyledTableCell>{d.BaseUnitOfMeasure}</StyledTableCell>
                                <StyledTableCell>{safeParseFloat(d?.UnrestrictedStock).toFixed(1)}</StyledTableCell>
                                <StyledTableCell>{safeParseFloat(d?.UnrestrictedStockToVendor).toFixed(1)}</StyledTableCell>
                                <StyledTableCell>{safeParseFloat(d?.StockInQualityInspection).toFixed(1)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default InventoryStatus;
