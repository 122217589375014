import React from "react";
import RouterHandler from "./routers/router";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate  } from '@azure/msal-react';
import Login from "../src/pages/login/login";

const App = () => {
  const { accounts } = useMsal();
  const activeAccount = accounts[0] || null;

  return (
    <div>
      <AuthenticatedTemplate>
        {activeAccount ? (
          <RouterHandler />
        ) : <Login />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
