import React, { useState, useContext, useEffect, useCallback } from "react";
import { Box, Button, Typography, Drawer } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SelectedAlertsContext } from "../../utils/SelectedAlertContext";
import InfoTileCard from "./infoTileCard";
import addToActionCartConstants from "../../constants/addToActionCart";
import { CollapseContext } from "../../utils/CollapseContext";
import { executeActionAPI } from "../../api/demo";
import styles from "./addToActionCart.module.css";
import ActionPopup from "components/popup/actionPopup";

const AddToActionCart = ({ open, onClose }) => {
  const { selectedAlerts, setSelectedAlerts } = useContext(SelectedAlertsContext);
  const [popupOpen, setPopupOpen] = useState(false);

  const { handleCollapseToggle } = useContext(CollapseContext);

  useEffect(() => {
    if (open && selectedAlerts.length === 0) {
      onClose();
    }
  }, [open, selectedAlerts, onClose]);

  const fetchData = async () => {
    try {
      const data = await executeActionAPI([alert["Alert Id"]]);
    } catch (error) {
      console.error("Error executing action:", error);
    }
  }

  const executeActionsButtonHandler = useCallback(() => {
    setPopupOpen(true);

    selectedAlerts.forEach((alert) => {
      handleCollapseToggle([alert["Alert Id"]], true);
    });
  }, [selectedAlerts, handleCollapseToggle])

  useEffect(() => {
    fetchData();
  }, [executeActionsButtonHandler])

  const handleDialogClose = () => {
    setPopupOpen(false);
  };


  const handleConfirmAction = () => {
    // Deselect all cards after executing actions
    setSelectedAlerts([]);
    // disableActionCartData(false)
    setPopupOpen(false);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} className={styles.drawer}>
      <Box bgcolor="#E1ECFF" padding="1vw" className={styles.drawer}>
        <div className={styles.stickyTitle}>
          <Typography fontWeight="600" variant="body2">
            {`${addToActionCartConstants.actionCartTitleText} (${selectedAlerts.length})`}
          </Typography>
          <div onClick={onClose}>
            <HighlightOffIcon className={styles.closeIcon} />
          </div>
        </div>
      </Box>

      <div className={styles.content}>
        {selectedAlerts.map((infoTileContent) => (
          <InfoTileCard
            // key={alert["Alert Id"]}
            key={infoTileContent["Alert Id"]}
            infoTileContent={infoTileContent}
          // disableActionCartData={disableActionCartData}
          ></InfoTileCard>
        ))}
      </div>

      <div className={styles.executeActions}>
        <Button className={styles.executeActionsBtn} onClick={executeActionsButtonHandler} >
          <Typography variant="body2" className={styles.btnText}>
            {addToActionCartConstants.executeActionsButtonDisplayText}
          </Typography>
        </Button>
      </div>
      <ActionPopup open={popupOpen} onClose={handleDialogClose} onConfirm={handleConfirmAction} source="addToActionCart" />
    </Drawer>
  );
};

export default AddToActionCart;
