import React from "react";
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import {
  ValueChainItems,
  RightTopArrowWithDot,
  RightBottomArrowWithDot,
  ArrowWithDot,
} from "./valueChainViewDetailedFlowComponents";
import landingConstants from "../../../constants/landing";
import landingExceptionsJsonData from "../../../assets/data/json/landingExceptions";
import styles from "./styles/valueChainPaneRight.module.css";

const MajorFlowSection = () => {
  const { majorFlowItems } = landingConstants;

  return (
    <>
      <div className={styles.majorFlowMainContainer}></div>
      {majorFlowItems.map((item, index) => (
        <React.Fragment key={index}>
          <div className={styles[`stage${index + 1}`]}>
            <span>{item}</span>
          </div>
          {index < majorFlowItems.length - 1 && (
            <div className={styles[`arrow${index + 1}`]}>
              <PiArrowFatLinesRightFill size={32} />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const ValueChainItemsSection = (stream) => {
  const { detailedFlowItems } = landingConstants;
  const { exceptionsData } = landingExceptionsJsonData;
  const valueChainData = stream.stream.value;

  // Sum up manufacturing exceptions
  const manufacturingExceptions = valueChainData?.Manufacturing?.reduce(
    (sum, count) => sum + count?.numberOfExceptions,
    0
  );

  // Create a mapping between detailedFlowItems and the corresponding data
  const valueChainMapping = [
    { index: 0, path: ["Supplier", 0], customClass: "supplierNetwork" },
    {
      index: 1,
      customData: manufacturingExceptions,
      customClass: "hulMfgUnit",
    },
    { index: 2, customClass: "collaborativeMfgUnit" },
    { index: 3, path: ["Distribution", 1], customClass: "motherDc" },
    { index: 4, path: ["Distribution", 0], customClass: "depot" },
    { index: 5, path: ["Distribution", 2], customClass: "distributorRs" },
    { index: 6, path: ["Customers", 0], customClass: "gtRetailer" },
    { index: 7, path: ["Customers", 1], customClass: "mt" },
    { index: 8, path: ["Customers", 2], customClass: "ecommFc" },
  ];

  return (
    <>
      {valueChainMapping.map(({ index, path, customData, customClass }) => {
        // Determine the entityAlertsData based on the path or custom data
        const entityAlertsData =
          customData !== undefined
            ? customData
            : path && valueChainData?.[path[0]]?.[path[1]]?.numberOfExceptions;

        return (
          <div
            key={detailedFlowItems[index].itemName}
            className={styles[customClass]}
          >
            <ValueChainItems
              valueChainItem={detailedFlowItems[index]}
              entityAlertsData={entityAlertsData || 0}
              exceptionsData={exceptionsData[index]}
            />
          </div>
        );
      })}
    </>
  );
};

const ArrowsSection = () => (
  <>
    {/* Non-straight arrows */}
    {[
      "depot2GtRetailer",
      "distributorRs2GtRetailer",
      "depot2EcommFc",
      "distributorRs2EcommFc",
    ].map((className) => (
      <div key={className} className={styles[className]}>
        {className.includes("GtRetailer") ? (
          <RightTopArrowWithDot />
        ) : (
          <RightBottomArrowWithDot />
        )}
      </div>
    ))}

    {/* Straight arrows */}
    {[
      "supplierNetwork2MfgUnit",
      "mfgUnit2MotherDc",
      "depot2DistributorRs",
      "motherDc2Depot",
      "distributorRs2Mt",
    ].map((className) => (
      <div key={className} className={styles[className]}>
        <ArrowWithDot />
      </div>
    ))}

    {/* Dashed background areas */}
    {[
      "supplierNetworkArea",
      "manufacturingArea",
      "distributionArea",
      "customersArea",
    ].map((className) => (
      <div
        key={className}
        className={`${styles[className]} ${styles.dashLeftRight} ${styles.dashBgColor}`}
      ></div>
    ))}
  </>
);

const ValueChainPaneRight = (stream) => {
  return (
    <div className={styles.valueChainGridContainer}>
      <MajorFlowSection />
      <ValueChainItemsSection stream={stream} />
      <ArrowsSection />
    </div>
  );
};

export default ValueChainPaneRight;
