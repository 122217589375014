import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./../components/header/header";
import Home from "../pages/home/home";
import Landing from "../pages/landing/landing";
import AlertDetails from "../pages/alertDeatils1/alertDeatils";
import PageNotFound from "../pages/pageNotFound/pageNotFound";

const appRoutes = [
  {
    path: "/",
    element: <Navigate to="/home/landing" replace />,
  },
  {
    path: "/home",
    element: <Navigate to="/home/landing" replace />,
  },
  {
    path: "/home",
    element: <Home />,
    children: [
      {
        path: "landing",
        element: <Landing />,
      },
      {
        path: "alert_details",
        element: <AlertDetails />,
      }
    ],
  },
];


const RouterHandler = () => {
  return (
    <><Header />
      <BrowserRouter>
        <Routes>
          {appRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((child, childIndex) => (
                  <Route
                    key={childIndex}
                    path={child.path}
                    element={child.element} />
                ))}
            </Route>
          ))}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter></>
  );
};

export default RouterHandler;
