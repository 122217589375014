import supplierNetworkImage from "../assets/images/landingPage/supplierNetwork.svg";
import hulMfgUnitImage from "../assets/images/landingPage/hulMfgUnit.svg";
import collaborativeMfgUnitImage from "../assets/images/landingPage/collaborativeMfgUnit.svg";
import motherDcImage from "../assets/images/landingPage/motherDc.svg";
import distributorRsImage from "../assets/images/landingPage/distributorRs.svg";
import depotImage from "../assets/images/landingPage/depot.png";
import gtRetailerImage from "../assets/images/landingPage/gtRetailer.svg";
import mtImage from "../assets/images/landingPage/mt.svg";
import ecommFcImage from "../assets/images/landingPage/ecommFc.svg";
import e2eValueChainImage from "../assets/images/landingPage/e2EValueChain.png";

const landingConstants = {
  exceptionPaneTitle: [
    {
      exceptionByType: "Alert by Type",
    },
  ],
  e2eValueChainText: "E2E Value chain view ⇢",
  e2eValueChain: {
    image: {
      name: "E2E Value Chain Image",
      path: e2eValueChainImage,
    },
  },
  majorFlowItems: ["Supplier", "Manufacturing", "Distribution", "Customers"],
  detailedFlowItems: [
    {
      itemName: "Supplier Network",
      clubbed: "no",
      multiLines: "no",
      displayText: "Supplier Network",
      image: {
        name: "Supplier Network",
        path: supplierNetworkImage,
      },
    },
    {
      itemName: "HUL MFG Unit",
      clubbed: "yes",
      multiLines: "no",
      displayText: "HUL MFG Unit",
      image: {
        name: "HUL MFG Unit",
        path: hulMfgUnitImage,
      },
    },
    {
      itemName: "Collaborative MFG Unit",
      clubbed: "yes",
      multiLines: "yes",
      displayText1: "Collaborative",
      displayText2: "MFG Unit",
      image: {
        name: "Collaborative MFG Unit",
        path: collaborativeMfgUnitImage,
      },
    },
    {
      itemName: "Mother DC",
      clubbed: "no",
      multiLines: "no",
      displayText: "Mother DC",
      image: {
        name: "Mother DC",
        path: motherDcImage,
      },
    },
    {
      itemName: "Depot",
      clubbed: "no",
      multiLines: "no",
      displayText: "Depot",
      image: {
        name: "Depot",
        path: depotImage,
      },
    },
    {
      itemName: "Distributor RS",
      clubbed: "no",
      multiLines: "no",
      displayText: "Distributor/RS",
      image: {
        name: "Distributor RS",
        path: distributorRsImage,
      },
    },
    {
      itemName: "GT Retailer",
      clubbed: "no",
      multiLines: "no",
      displayText: "GT Retailer",
      image: {
        name: "GT Retailer",
        path: gtRetailerImage,
      },
    },
    {
      itemName: "MT",
      clubbed: "no",
      multiLines: "no",
      displayText: "MT",
      image: {
        name: "MT",
        path: mtImage,
      },
    },
    {
      itemName: "EComm FC",
      clubbed: "no",
      multiLines: "no",
      displayText: "EComm FC",
      image: {
        name: "EComm FC",
        path: ecommFcImage,
      },
    },
  ],
};

export default landingConstants;
