export const simulateScenarioJson = {
    "Alert Description": "RM shortage at HN Enterprises expected to impact planned production at Haridwar factory",
    "Current Scenario": {
        "title": "Current Scenario",
        "data": [
            {
                "label": "Revenue Opportunity (₹)",
                "value": 0
            },
            {
                "label": "Gross Margin Loss Recov.(₹)",
                "value": 0
            },
            {
                "label": "Inc. In Supply Chain Cost",
                "value": 0
            },
            {
                "label": "Quantity At Risk Addressed",
                "value": 0
            },
            {
                "label": "OR Percentage",
                "value": 0
            }
        ]
    },
    "Selected Scenario": {
        "Selected": ["Expedited Shipment", "Inter-Unit Transfer of RM/PM"],
        "data": [
            {
                "label": "Revenue Unlocaked (₹)",
                "value": 0
            },
            {
                "label": "Gross Margin Loss Recov.(₹)",
                "value": 0
            },
            {
                "label": "Inc. In Supply Chain Cost",
                "value": 0
            },
            {
                "label": "Quantity At Risk Addressed",
                "value": 0
            },
            {
                "label": "OR Percentage",
                "value": 0
            }
        ]
    },
    "Simulate data": {
        "weeks": [
            "Week 1",
            "Week 2",
            "Week 3",
            "Week 4"
        ],
        "Week 1": [
            {
                "Date": "01-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "02-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "03-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "04-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "05-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "06-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "07-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            }
        ],
        "Week 2": [
            {
                "Date": "08-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "09-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "10-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "11-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "12-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "13-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "14-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            }
        ],
        "Week 3": [
            {
                "Date": "15-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "16-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected Demand for RM (in T) Next": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "17-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "18-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "19-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "20-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "21-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            }
        ],
        "Week 4": [
            {
                "Date": "22-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "23-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "24-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "25-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "26-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "27-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },
                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            },
            {
                "Date": "28-09-2024",
                "Projected Demand for RM (in T)": {
                    "value": 0,
                    "Planned Production Order (in T)": 0,
                    "Stock Transfer - Out (in T)": 0
                },

                "Projected RM Availability Old (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Projected RM Availability New (in T)": {
                    "value": 0,
                    "Planned Inbound Supply (in T)": 0,
                    "Predicated Inbound Supply (in T)": 0,
                    "Stock Transfer-Out (in T)": 0,
                    "Beginning on Hand (in T)": 0,
                    "Hold due to quality/freshn (in T)": 0,
                    "Safety Stock (in T)": 0
                },
                "Ending on Hand Old (in T)": 0,
                "Ending on Hand New (in T)": 0,
                "Revenue at Risk Addressed (in INR L)": 0,
                "Expected Resolved": 0
            }
        ]
    }
}
