import React from "react";
import styles from "./periodLineTab.module.css";

const PeriodLineTab = ({ selectedLine, horizontalLineHandler, children, values }) => {
  return (
    <div className={styles.periodContainer}>
      <div className={styles.mainLineContainer}>
        {values.map((line, index) => {
          const isSelected = selectedLine === line;
          
          return (
            <div key={index} className={styles.lineContainer} onClick={() => horizontalLineHandler(line)}>
              <div className={`${styles.lineWrapper} ${isSelected ? "selected" : ""}`}>
                <svg className={styles.lineSvg} viewBox="0 0 400 20" xmlns="http://www.w3.org/2000/svg">
                  <line x1="0" y1="10" x2="400" y2="10" stroke={isSelected ? "blue" : "#d8d8d8"} strokeWidth="1"/>
                  {isSelected && (
                    <>
                      <line x1="0" y1="0" x2="0" y2="20" stroke="#D8D8D8" strokeWidth="2" />
                      <polygon points="0,10 10,0 10,20" fill="blue" />
                      <line x1="400" y1="0" x2="400" y2="20" stroke="#D8D8D8" strokeWidth="2" />
                      <polygon points="400,10 390,0 390,20" fill="blue" />
                    </>
                  )}
                </svg>
              </div>
              <div className={styles.periodTitleText} style={{ color: isSelected ? "#FF647C" : "rgba(0, 0, 0, 0.6)" }}>
                WK {line.split(' ')[1]}
              </div>
            </div>
          );
        })}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PeriodLineTab;
