export const devCred = {
    clientId: "438bd009-4242-4857-801c-2ad5bbde8033",
    authority:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
    tokenEndpoint:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token",
    authorizationEndpoint:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/authorize",
    clientSecret: "fUs8Q~ncYn5MKcNPjC.SxoNHYb.4Bsa3kFdrjbmY",
};

export const qaCred = {
    clientId: "b20c80b6-bc34-46bc-8ecb-46502a3fe38d",
    authority:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
    tokenEndpoint:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token",
    authorizationEndpoint:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/authorize",
    clientSecret: "JBJ8Q~2TjC5MEmA2aPE2d9Fk.9Rik.ioZYkBvcEG",
};

export const prodCred = {
    clientId: "17ccbf55-bbc7-4d5f-9d7d-581906d053ec",
    authority:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
    tokenEndpoint:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/token",
    authorizationEndpoint:"https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/v2.0/authorize",
    clientSecret: "rBH8Q~mOy2kfjHqjunmAB9~YXMaqo.zsmeCrhdiG",
}

export const falguniCred = {
    clientId: "b1a871a1-bce3-4ebf-b499-05c1adfdf267",
    authority:"https://login.microsoftonline.com/falguniagrawal7gmailcom.onmicrosoft.com",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
}

export const himanshiCred = {
    clientId: "1a13687d-fb79-45e1-a832-6190928a9a6a",
    authority:"https://login.microsoftonline.com/himanshibantegmail.onmicrosoft.com",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
}