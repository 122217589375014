import React from "react";
import { PiWarning } from "react-icons/pi";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircleIcon from "@mui/icons-material/Circle";
import styles from "./styles/valueChainViewDetailedFlowComponents.module.css";
import entityExceptionsConstants from "../../../constants/entityExceptions";
import { useNavigate } from "react-router-dom";
const circlefontsize = 6;

export const ValueChainItems = ({
  valueChainItem,
  entityAlertsData,
  exceptionsData,
}) => {
  const navigate = useNavigate();

  const handleAlertButtonClick = (value) => {
    if (value === "Supplier Network") {
      const currentRoutesHistoryData = {
        prev_route: entityExceptionsConstants.previousRoute,
        other_routes: [
          {
            name: exceptionsData.entity,
            route_present: "yes",
            route: entityExceptionsConstants.previousRoute,
          },
          {
            name: exceptionsData.exceptionTypes[0].name,
            route_present: "no",
          },
        ],
      };
      navigate(entityExceptionsConstants.routeToAlertDetailsPage, {
        state: {
          routesHistoryData: currentRoutesHistoryData,
        },
      });
    }
  };

  const renderValueChainItem = (containerClass, displayTexts) => (
    <div className={styles.valueChainItem}>
      {entityAlertsData && (
        <div
          className={styles.warningContainer}
          onClick={() => handleAlertButtonClick(valueChainItem.image.name)}
        >
          <PiWarning className={styles.warningIcon} />
          <p className={styles.warningValue}>{entityAlertsData}</p>
        </div>
      )}
      <div className={containerClass}>
        <img
          className={styles.valueChainItemsImage}
          alt={valueChainItem.image.name}
          src={valueChainItem.image.path}
        />
        {displayTexts.map((text, index) => (
          <p key={index} className={styles.valueChainItemsName}>
            {text}
          </p>
        ))}
      </div>
    </div>
  );

  if (valueChainItem.clubbed === "no") {
    return renderValueChainItem(styles.valueChainItemsContainer, [
      valueChainItem.displayText,
    ]);
  } else if (
    valueChainItem.clubbed === "yes" &&
    valueChainItem.multiLines === "no"
  ) {
    return renderValueChainItem(styles.valueChainItemsContainerUp, [
      valueChainItem.displayText,
    ]);
  } else if (
    valueChainItem.clubbed === "yes" &&
    valueChainItem.multiLines === "yes"
  ) {
    return renderValueChainItem(styles.valueChainItemsContainerDown, [
      valueChainItem.displayText1,
      valueChainItem.displayText2,
    ]);
  } else {
    return null;
  }
};

export const RightTopArrowWithDot = () => {
  return (
    <>
      <div className={styles.topArrowRightIcon}>
        <ArrowRightIcon />
      </div>
      <div className={styles.topCircleIcon}>
        <CircleIcon sx={{ fontSize: circlefontsize }} />
      </div>
    </>
  );
};

export const RightBottomArrowWithDot = () => {
  return (
    <>
      <div className={styles.bottomArrowRightIcon}>
        <ArrowRightIcon />
      </div>
      <div className={styles.bottomCircleIcon}>
        <CircleIcon sx={{ fontSize: circlefontsize }} />
      </div>
    </>
  );
};

export const ArrowWithDot = () => {
  return (
    <div className={styles.arrowWithDot}>
      <div className={styles.circleIcon}>
        <CircleIcon sx={{ fontSize: circlefontsize }} />
      </div>
      <div className={styles.arrowRightIcon}>
        <ArrowRightIcon />
      </div>
    </div>
  );
};
