import React from "react";
import { Snackbar, Alert } from "@mui/material";

const SnackBarMessage = ({ open, handleClose, message }) => {
    return (
        <Snackbar open={open} onClose={handleClose}  >
            <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackBarMessage;
