import React, { useEffect, useState } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

const ProgressBarChart = (alertStatusCounts) => {
    const [closedData, setClosedData] = useState({ count: 0, percent: 0 });
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (alertStatusCounts?.data) {
            const total = alertStatusCounts.data.reduce((sum, item) => sum + item.value, 0);
            const closedItem = alertStatusCounts.data.find(item => item.name === 'Closed') || { value: 0 };
            const closedPercentage = total ? ((closedItem.value / total) * 100).toFixed(0) : 0;

            setClosedData({ count: closedItem.value, percent: parseFloat(closedPercentage) });
            setTotalCount(total);
        }
    }, [alertStatusCounts]);

    return (
        <Box sx={{ width: '100%', margin: 'auto' }}>
            <Typography variant="body1" sx={{ mb: 1, fontSize: '12px' }}>
                Closed : ({closedData.count}/{totalCount})
            </Typography>

            <Box sx={{ position: 'relative', width: '100%' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        left: closedData.percent === 0 ? '5px' : `calc(${closedData.percent}% - 25px)`,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                        pointerEvents: 'none',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: '500',
                            color: closedData.percent === 0 ? 'black' : '#FFFFFF',
                            fontSize: '12px',
                        }}
                    >
                        {closedData.percent}%
                    </Typography>
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={100}
                    sx={{
                        height: 20,
                        borderRadius: 10,
                    }}
                />
                <LinearProgress
                    variant="determinate"
                    value={closedData.percent}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        backgroundColor: '#E0E0E0',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#4caf50',
                            borderRadius: '10px 0 0 10px',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default ProgressBarChart;
