const alertDetailsConstants = {
  exceptionSplitTitle: "Exception Split",
  revenueSplitTitle: "Revenue Split",
  addToActionCartTitle: "Action Cart",
  resetFilterTitle: "Reset Filter",
  executeActionsButtonDisplayText: "Execute Action",
  simulateScenarioButtonDisplayText: "Simulate Scenario",
  routeToAlertDemandSupplyAndKeyInsightsPage:
    "/home/alert_demand_supply_key_insights",
  previousRoute: -1,
  secondPreviousRoute: -2,
};

export default alertDetailsConstants;
