const isLocalhostTrue = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

const config = {
  environmentName: "Development",
  landingApi: isLocalhostTrue ? "http://localhost:8000/api/landing" : "/api/landing",
  alertDetailApi: isLocalhostTrue ? "http://localhost:8000/api/RMPMShortage" : "/api/RMPMShortage",
  demandDetailApi: isLocalhostTrue ? "http://localhost:8000/api/DemandSupply" : "/api/DemandSupply",
  keyInsight: isLocalhostTrue ? "http://localhost:8000/api/keyInsights" : "/api/keyInsights",
  executeAction: isLocalhostTrue ? "http://localhost:8000/api/executeAction" : "/api/executeAction",
};

export default config;