import React from "react";
import { Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import addToActionCartConstants from "../../constants/addToActionCart";
import styles from "./popup.module.css";
import Modal from "@mui/material/Modal";

const ActionPopup = ({ open, onClose, title, source, onConfirm }) => {
  const getButtonLabel = () => {
    if (source === "resolve") {
      return `${title} has been successfully executed`;
    }
    if (source === "addToActionCart") {
      return "All requests have been successfully executed!";
    }
    return "Submit";
  };

  const handleOkClick = () => {
    if (onConfirm) {
      onConfirm();
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.modalContainer}>
        <div className={styles.dialogBox}>
          <CheckCircleIcon className={styles.dialogIcon} />
          <div className={styles.dialogBoxContent}>
            <Typography className={styles.buttonLabel}>
              {getButtonLabel()}
            </Typography>
            <Button
              variant="contained"
              onClick={handleOkClick}
              className={styles.dialogButton}
            >
              {addToActionCartConstants.okButtonDisplayText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActionPopup;

