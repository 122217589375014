import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import styles from './MultiSelectCheckbox.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';

const menuProps = {
    MenuListProps: {
        style: {
            width: 'auto',
            padding: 0,
        },
    },
    PaperProps: {
        style: {
            width: 'auto',
            height: 'auto',
            maxHeight: 300,
        },
    },
};

const SelectMultipleFilterInput = ({ label, options, selected, onChange }) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedValue, setSelectedValue] = useState(selected);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        let sortedOptions = options;
        if (label === 'Week') {
          sortedOptions = [...options].sort((a, b) => {
            const [fyA, weekA] = a.split('-');
            const [fyB, weekB] = b.split('-');
            return fyA === fyB
              ? parseInt(weekA) - parseInt(weekB)
              : fyA.localeCompare(fyB);
          });
        } else if (typeof options[0] === 'number') {
          sortedOptions = [...options].sort((a, b) => a - b);
        } else {
            sortedOptions = [...options].sort((a, b) => {
                // Add null/undefined check
                const strA = a != null ? a.toString() : '';
                const strB = b != null ? b.toString() : '';
                return strA.localeCompare(strB);
            });        }
        setFilteredOptions(sortedOptions);
        setSelectedValue(selected);
    }, [options, selected]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const updatedSelectedValue = typeof value === 'string' || typeof value === 'number' ? value.split(',') : value;
        onChange(updatedSelectedValue)
        setSelectedValue(updatedSelectedValue);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value.toLowerCase(); // Normalize search term
        setSearchTerm(newSearchTerm);
        
        // Reset to all options if search term is empty
        if (!newSearchTerm.trim()) {
            setFilteredOptions(options);
            return;
        }
        
        // Filter based on the search term
        const filtered = options.filter((a) => {
            if (typeof a === 'string') {
                return a.toLowerCase().includes(newSearchTerm);
            } else if(typeof a === 'number'){
                return a.toString().includes(newSearchTerm)
            }

            return false;
        });
        setFilteredOptions(filtered);
    };

    return (
        <div className={styles.SelectInputDiv}>
            <label className={styles.SelectInputlabel}>{label.includes("Material Description") ? 'Material Desc' : label }</label>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                displayEmpty
                value={selectedValue}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.length === 0 ? 'All' : selected.join(', ')}
                MenuProps={menuProps}
                className={styles.customClass}
                IconComponent={(props) => <KeyboardArrowDownIcon {...props} style={{ color: '#292929' }} />}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent', border: 'none' },
                    '& .MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent', border: 'none', position: 'unset' },
                }}
            >
                {
                    <MenuItem key="search" sx={{ padding: 0, borderBottom: '1px solid #ccc', '&.MuiMenuItem-root:hover': { backgroundColor: 'transparent' } }}>
                        <input type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className={styles.SearchInput}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            style={{ border: 'none', height: '30px' }}
                            sx={{ '& .MuiMenu-paper': {width: '200px'}}}
                        />
                    </MenuItem>}
                {filteredOptions && filteredOptions.map((name) => (
                    <MenuItem key={name} value={name} className={styles.menuItem}            
                     sx={{
                        width: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize: "12px",
                      }}>
                        <Checkbox size="small" checked={selectedValue.indexOf(name) > -1} className={styles.checkbox} sx={{ '& .MuiSvgIcon-root': { width: 15, height: 15 } }} />
                        <ListItemText primary={name} className={styles.menuListItemText} sx={{ '& .MuiTypography-root': { color: '#000', fontSize: 12, fontFamily: "Unilever Shilling", fontWeight: 600 } }} />
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}

export default SelectMultipleFilterInput;
