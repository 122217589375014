import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SelectedAlertsContext } from "../../utils/SelectedAlertContext";
import { CollapseContext } from "../../utils/CollapseContext";
import Resolve from "./../../pages/alertDeatils1/component/resolve";
import styles from "./table.module.css";
import DemandPopup from "components/popup/demandPopup";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Unilever Shilling",
    color: "#292929",
    textAlign: "center",
    borderBottom: "2px solid rgb(224 224 224)",
    fontSize: "12px",
    fontWeight: 600,
    backgroundColor: "#DAE8FF",
    borderRight: "2px solid #FFF",
    padding: "5px",
    '@media (max-width: 1500px)': {
      fontSize: "10px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Unilever Shilling",
    fontSize: "12px",
    borderRight: "2px solid #FFF",
    "&:last-child": {
      borderRight: 0,
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    '@media (max-width: 1500px)': {
      fontSize: "10px",
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#EAF2FF',
  "& td, & th": {
    textAlign: "center",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const AlertTable = ({ data }) => {
  const { setSelectedAlerts } = useContext(SelectedAlertsContext);
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState({ "type": "", alertId: "", materialId: "" });
  const [sortedData, setSortedData] = useState(data);
  const [alertData, setAlertData] = useState(sortedData);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Alert Id"); // Column to sort by

  const { openRows, handleCollapseToggle } = useContext(CollapseContext);

  const handleClose = () => {
    setOpen(false);
  };

  const openKeyInsgits = (type, alertId, materialId, plantCode, week) => {
    const weekNumber = week.replace(/\D/g, "");
    setDialogType({ type: type, alertId: alertId, materialId: materialId, plantCode, week: weekNumber });
    setOpen(true);
  };

  const handleCardSelect = (isCardSelected, alert) => {
    setSelectedAlerts((prevSelected) => {
      const alertIndex = prevSelected?.findIndex(
        (a) => a["Alert Id"] === alert["Alert Id"]
      );
      let updatedSelectedAlerts;

      if (alertIndex > -1) {
        const updatedAlert = {
          ...prevSelected[alertIndex],
          selectedCard: isCardSelected,
        };
        updatedSelectedAlerts = [...prevSelected];
        updatedSelectedAlerts[alertIndex] = updatedAlert;
      } else {
        updatedSelectedAlerts = [
          ...prevSelected,
          { ...alert, selectedCard: isCardSelected },
        ];
      }
      return updatedSelectedAlerts;
    });
  };

  const handleSort = (column) => {
    const newSortDirection = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    const sorted = [...sortedData].sort((a, b) => {
      if (newSortDirection === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    setSortDirection(newSortDirection);
    setSortColumn(column);
    setSortedData(sorted);
  };

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const sortableHeaders = [
    { label: "Alert Id", key: "Alert Id" },
    { label: "Alert Type", key: "Alert Type" },
    { label: "Quantity at Risk", key: "Quantity at Risk" },
    { label: "Alert Status", key: "Alert Status" },
  ];

  const handleStatusChange = ({ alertId, status }) => {
    setAlertData((prevData) =>
      prevData.map((alert) =>
        alert["Alert Id"] === alertId
          ? { ...alert, "Alert Status": status }
          : alert
      )
    );
  };

  return (
    <>
      <TableContainer className={styles.table} style={{ height: 'auto' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {sortableHeaders.map(({ label, key }) => (
                <StyledTableCell key={key} onClick={() => handleSort(key)}>
                  <div className={styles.sortColumn}>
                    {label}
                    {sortColumn === key && sortDirection === "asc" ? (
                      <ArrowUpwardIcon className={styles.sortIcon} />
                    ) : (
                      sortColumn === key && <ArrowDownwardIcon className={styles.sortIcon} />
                    )}
                  </div>
                </StyledTableCell>
              ))}
              <StyledTableCell>Insights</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertData?.map((alert, index) => (
              <React.Fragment key={alert["Alert Id"] + index}>
                <StyledTableRow>
                  <StyledTableCell>{alert["Alert Id"]}</StyledTableCell>
                  <StyledTableCell>{alert["Alert Type"]}</StyledTableCell>
                  <StyledTableCell>{alert["Quantity at Risk"] === null ? 0 : alert["Quantity at Risk"].toFixed(0) + ' ' + alert["Base Unit of Measure"]}</StyledTableCell>
                  <StyledTableCell>{alert["Alert Status"]}</StyledTableCell>
                  <StyledTableCell className={styles.insightBtn} onClick={() => openKeyInsgits("Insights", alert["Alert Id"], alert["Material ID"], alert["Site Code"], alert["Week"])}>View</StyledTableCell>
                  <StyledTableCell>
                    <Button className={styles.resolvedBtn} disabled={alert["isRecommended"] === false} onClick={() => handleCollapseToggle(alert["Alert Id"])} >
                      Resolve{" "}
                      {openRows[alert["Alert Id"]] ? (<KeyboardArrowUpIcon className={styles.arrowBtn} />) : (<KeyboardArrowDownIcon className={styles.arrowBtn} />)}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell className={styles.collapseSection} colSpan={14} padding="0">
                    <Collapse in={openRows[alert["Alert Id"]]} timeout="auto" unmountOnExit>
                      <div style={{ padding: "10px" }} >
                        <Resolve alert={alert} onCardSelect={(isCardSelected) => handleCardSelect(isCardSelected, alert)}
                          onStatusChange={handleStatusChange}
                          onCollapseClose={handleCollapseToggle} />
                      </div>
                    </Collapse>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DemandPopup open={open} handleClose={handleClose} type={dialogType.type} alertId={dialogType.alertId} materialId={dialogType.materialId} plantCode={dialogType.plantCode} week={dialogType.week} />
    </>
  );
};

export default AlertTable;
