import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    IconButton,
    tableCellClasses,
    TablePagination
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AlertTable from "./alertTable";
import styles from "./table.module.css";
import CustomTooltip from "../../utils/CustomTooltip";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontFamily: "Unilever Shilling",
        color: "#292929",
        textAlign: "center",
        borderBottom: "none",
        fontSize: "12px",
        fontWeight: 600,
        backgroundColor: "rgb(208, 227, 255)",
        borderRight: "2px solid #FFF",
        padding: "5px",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: "Unilever Shilling",
        fontSize: "12px",
        borderRight: "2px solid #FFF",
        "&:last-child": {
            borderRight: 0,
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '@media (max-width: 1500px)': {
            fontSize: "10px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    "& td, & th": {
        textAlign: "center",
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

const CollapsibleAlertTable = ({ data, alertCounts }) => {

    const [sortDirection, setSortDirection] = useState("asc");
    const [sortColumn, setSortColumn] = useState("Material ID");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openRows, setOpenRows] = useState({});

    useEffect(() => {
        setPage(0);
    }, [data]);

    if (!data || typeof data !== "object" || Array.isArray(data)) {
        console.error("Invalid data passed to CollapsibleAlertTable:", data);
        return <div>No data available</div>;
    }

    const totalAlertCount = Object.values(alertCounts).reduce((sum, count) => sum + count, 0);

    // const handleSort = (column) => {
    //     const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    //     setSortDirection(direction);
    //     setSortColumn(column);
    // };

    // const sortedData = [...data].sort((a, b) => {
    //     if (a[sortColumn] === null || b[sortColumn] === null) return 0; // Handle null values
    //     if (sortDirection === "asc") {
    //         return a[sortColumn] > b[sortColumn] ? 1 : -1;
    //     } else {
    //         return a[sortColumn] < b[sortColumn] ? 1 : -1;
    //     }
    // });


    const handleExpandCollapse = (key) => {
        setOpenRows((prevKey) => (prevKey === key ? null : key));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <TableContainer className={styles.table} style={{ maxHeight: 'calc(-300px + 100vh)', overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {[
                                "Material ID",
                                "Material Description",
                                "Site Code",
                                "Site Cluster",
                                "Alert Details",
                            ]?.map((column) => (
                                <StyledTableCell key={column} className={column === "Alert Details" ? styles.alertDetailsColumn : ""}>
                                    <div className={styles.sortColumn}>
                                        {column}
                                        {/* {sortColumn === column &&
                                            (sortDirection === "asc" ? (
                                                <ArrowUpwardIcon className={styles.sortIcon} />
                                            ) : (
                                                <ArrowDownwardIcon className={styles.sortIcon} />
                                            ))} */}
                                    </div>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(data)
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .slice(page * rowsPerPage, Math.min(page * rowsPerPage + rowsPerPage, totalAlertCount))
                            .map((key) => (
                                <React.Fragment key={key}>
                                    <StyledTableRow>
                                        <StyledTableCell>{data[key][0]["Material ID"]}</StyledTableCell>
                                        <StyledTableCell className={styles.ellipsisText}>
                                            <CustomTooltip title={data[key][0]["Material Description"]}>
                                                <span>{data[key][0]["Material Description"]}</span>
                                            </CustomTooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>{data[key][0]["Site Code"]}</StyledTableCell>
                                        <StyledTableCell>{data[key][0]["Site Cluster"]}</StyledTableCell>
                                        <StyledTableCell>
                                            {/* {data[key].length} */}
                                            <IconButton onClick={() => handleExpandCollapse(key)}>
                                                {openRows === key ? <RemoveIcon sx={{ fontSize: '18px', fontWeight: '600', color: '#1f36c7' }} /> :
                                                    <AddIcon sx={{ fontSize: '18px', fontWeight: '600', color: '#1f36c7' }} />}
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={5} style={{ padding: 0 }}>
                                            <Collapse in={openRows === key} timeout="auto" unmountOnExit>
                                                <div style={{ padding: "16px" }}>
                                                    <AlertTable data={data[key]} />
                                                </div>
                                            </Collapse>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={totalAlertCount}
                    // count={Object.keys(data).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[]} // Example options
                    sx={{
                        padding: 0,
                        "& .MuiTablePagination-toolbar": { minHeight: "20px", fontSize: "12px" },
                        "& .MuiTablePagination-select": { fontSize: "12px" },
                        "& .MuiTablePagination-selectLabel": { fontSize: "12px" },
                        "& .MuiTablePagination-displayedRows": { fontSize: "12px" },
                        "& .MuiTablePagination-actions": { marginLeft: "10px" },
                        "& .MuiIconButton-root": { fontSize: "14px" },
                        "& .MuiSvgIcon-root": { height: "14px", width: "14px", fontSize: "14px" },
                    }}
                />
            </TableContainer>
        </>
    );
};

export default CollapsibleAlertTable;
