import Tooltip from '@mui/material/Tooltip';

const CustomTooltip = ({ title, children, placement='left-start', ...props }) => {
  return (
    <Tooltip title={title} placement={placement} componentsProps={{ tooltip: { sx: { fontFamily: "Unilever Shilling", fontSize: '10px' } } }} {...props}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
