import React, { useState, useContext, useEffect, useCallback } from "react";
import { Button, Checkbox } from "@mui/material";
import { ThumbUp } from "@mui/icons-material";
import { SelectedAlertsContext } from "../../../utils/SelectedAlertContext";
import alertDetailsConstants from "../../../constants/alertDetails";
import styles from "./resolve.module.css";
import DemandPopup from "components/popup/demandPopup";
import ActionPopup from "components/popup/actionPopup";
import { executeActionAPI } from "../../../api/demo"
import { format } from 'date-fns';

const Resolve = ({ alert, onCardSelect, onCollapseClose, onStatusChange }) => {
  const { selectedAlerts, setSelectedAlerts } = useContext(SelectedAlertsContext);
  const [selectedCards, setSelectedCards] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [simulateDialogOpen, setSimulateDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [resolveData, setResolveData] = useState([]);

  useEffect(() => {
    if (alert?.resolveData) {
      setResolveData(alert?.resolveData);
    }
  }, [alert?.resolveData]);

  useEffect(() => {
    const currentSelected = selectedAlerts.find(
      (selectedAlert) => selectedAlert["Alert Id"] === alert["Alert Id"]
    );
    if (currentSelected) {
      setSelectedCards(currentSelected.selectedCard);
    } else {
      setSelectedCards([]);
    }
  }, [selectedAlerts, alert["Alert Id"]]);

  const handleCheckboxChange = (card) => {
    setSelectedCards((prevSelected) => {
      const isSelected = prevSelected.some((c) => c.Title === card.Title);
      const updatedSelection = isSelected
        ? prevSelected.filter((c) => c.Title !== card.Title)
        : [...prevSelected, card];

      onCardSelect(updatedSelection);

      if (updatedSelection.length === 0) {
        setSelectedAlerts((prevAlerts) =>
          prevAlerts.filter((selectedAlert) => selectedAlert["Alert Id"] !== alert["Alert Id"])
        );
      }
      return updatedSelection;
    });
  };

  const calculateCumulativeQuantity = () => {
    return selectedCards.reduce((sum, card) => {
      const quantityAtRisk = card.data.find((d) => d.label === "Quantity At Risk Recovered")?.value || 0;
      return sum + Number(quantityAtRisk);
    }, 0);
  };

  const isCardDisabled = (card) => {

    if (alert["Alert Status"] === "Closed") {
      return true;
    }

    const cumulativeQuantity = calculateCumulativeQuantity();

    const isCardSelected = selectedCards.some((selected) => selected.Title === card.Title);

    if (isCardSelected) {
      return false; // Do not disable if the card is already selected
    }

    // Disable the card if cumulative quantity exceeds the threshold
    const remainingCapacity = Number(alert["Quantity at Risk"]) - cumulativeQuantity;
    return remainingCapacity <= 0;
  };

  const handleExecuteActionClick = useCallback(async () => {
    const selectedTitles = selectedCards.map((card) => card.Title).join(", ");
    setDialogTitle(selectedTitles);
    setDialogOpen(true);

    await executeActionAPI([alert["Alert Id"]]);

    const updatedAlerts = selectedAlerts.map((selectedAlert) => {
      if (selectedAlert["Alert Id"] === alert["Alert Id"]) {
        return { ...selectedAlert, "Alert Status": "Closed" };
      }
      return selectedAlert;
    });
    setSelectedAlerts(updatedAlerts);

    onStatusChange({
      alertId: alert["Alert Id"],
      status: "Closed",
    });

    onCollapseClose(alert["Alert Id"], true);

    setSelectedCards([]);
    onCardSelect([]);

    // const updatedAlerts = selectedAlerts.filter(
    //   (selectedAlert) => selectedAlert["Alert Id"] !== alert["Alert Id"]
    // );
    // setSelectedAlerts(updatedAlerts);

  }, [selectedCards, alert, onCardSelect, selectedAlerts, setSelectedAlerts, onCollapseClose]);


  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSimulateScenarioClick = () => {
    setSimulateDialogOpen(true);
  };

  const handleSimulateDialogClose = () => {
    setSimulateDialogOpen(false);
  };

  return (
    <div className={styles.resolveContainer}>
      <div className={styles.resolveTitle}>
        <span className={styles.title}>Intelligent Recommendations</span>
        <Button
          disabled={selectedCards.length === 0}
          className={styles.actionBtn}
          onClick={handleExecuteActionClick}
        >
          {alertDetailsConstants.executeActionsButtonDisplayText}
        </Button>
      </div>
      <div className={styles.resolveBody}>
        {resolveData?.map((card, index) => (
          <div key={index} className={`${styles.card} ${isCardDisabled(card) ? styles.disabledCard : ""}`}>
            <div className={styles.cardHeader}>
              {card.Title}
              <span className={styles.cardIcons}>
                {card.isRecommendated && <ThumbUp className={styles.thumpIcon} />}
                {!card.Title.includes("Current") && (
                  <Checkbox
                    disableRipple
                    sx={{
                      padding: 0,
                      height: "16px",
                      width: "16px",
                      "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    onChange={() => handleCheckboxChange(card)}
                    checked={selectedCards.some((c) => c.Title === card.Title)}
                    disabled={isCardDisabled(card)}
                  />
                )}
              </span>
            </div>
            <div className={styles.cardBody}>
              {card.data.map((d, dataIndex) => (
                // (d.label === "Supplying Site" && d.value !== null) &&
                !d.label.includes('Base Unit of Measure') && (
                  <div key={`${index}-${dataIndex}`} className={styles.cardContain}>
                    <span className={styles.label}>{d.label}</span>
                    {/* <span className={styles.colon}>:</span> */}
                    <span className={styles.value}>
                      {d.value !== null &&
                        (d?.label === "Quantity At Risk" ?
                          Math.min(Number(d.value) || 0, Number(alert["Quantity at Risk"]) || 0).toFixed(0) :
                          d?.label === "Quantity At Risk Recovered" ?
                            Math.min(Number(d.value) || 0, Number(alert["Quantity at Risk"]) || 0).toFixed(0) :
                            // d.value.toFixed(0)  : 
                            d.label.includes("Date") ?
                              format(new Date(d.value), 'dd-MMM-yy') :
                              d.value)}
                      {(d.label.includes("Cost") ||
                        d.label.includes("Revenue") ||
                        d.label.includes("Gross")) &&
                        d.value !== 0 && <span>L</span>}
                      {d.value !== null && d.label.includes("Quantity") && (
                        <span>
                          {' '}
                          {card.data.find((item) => item.label === "Base Unit of Measure")?.value}
                        </span>
                      )}
                      {d.value !== null && d.label.includes("Percentage") && <span>%</span>}
                    </span>
                  </div>
                )
              ))}
            </div>
          </div>
        ))}
      </div>
      <DemandPopup open={simulateDialogOpen} handleClose={handleSimulateDialogClose} />
      <ActionPopup open={dialogOpen} onClose={handleDialogClose} title={dialogTitle} source="resolve" />
    </div>
  );
};

export default Resolve;


