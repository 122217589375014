import devURL from "./../config/developmentEnvironment";

/**
 * Function to Test API
 * @param {*} requestObj 
 * @returns 
 */
export async function demoAPI(requestObj) {
  const url = devURL.demoApi;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call landing API
 * @returns 
 */
export async function landingAPI() {
  const url = devURL.landingApi;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call Alert Details
 * @returns 
 */
export async function alertDetailAPI() {
  const url = devURL.alertDetailApi;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}


/**
 * Function to call Demand Supply API
 * @param {Alert Id} alertId 
 * @returns 
 */
export async function demandSupplyAPI(alertId) {
  const url = devURL.demandDetailApi;
  const requestObj = {
    "Alert Id": alertId
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj)
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call Key Insight API
 * @param {Material Number} materialId 
 * @returns 
 */
export async function keyInsightApi(materialId, plantCode, week) {
  const url = devURL.keyInsight;
  const requestObj = {
    "Material Number": materialId,
    "Plant Code": plantCode,
    "week": week
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj)
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}

/**
 * Function to call execute Action API
 * @param {Alert Id} alertIds
 * @returns 
*/
export async function executeActionAPI(alertIds) {
  const url = devURL.executeAction;

  const requestObj = {
    alertIds: alertIds,
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const responseDataText = await response.json();

    return responseDataText;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
}